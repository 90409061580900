export function useToast() {
    const store = useStore();

    const error = (message: string, duration: number = 4000) => {
        store.addToastNotification(message, "danger", duration);
    };

    const info = (message: string, duration: number = 4000) => {
        store.addToastNotification(message, "primary", duration);
    };

    const success = (message: string, duration: number = 4000) => {
        store.addToastNotification(message, "success", duration);
    };

    const warning = (message: string, duration: number = 4000) => {
        store.addToastNotification(message, "warning", duration);
    };

    const clear = () => {
        store.toastNotifications = [];
    };
    return { error, info, success, warning, clear };
}
